<template>
    <div class="paper-manage">
        <PaperManageHead
            :searchVal.sync="getListParams.search"
            :questionLibraryList="questionLibraryList"
            :questionLibraryValue.sync="getListParams.category_id"
        />
        <PaperManageTable :tableData="packTableData" :deletePaper="deletePaper" />
        <div class="footer-paging">
            <el-pagination
                :current-page.sync="getListParams.page"
                :page-size="getListParams.pageSize"
                layout="prev, pager, next, jumper"
                :total="totalPaperCount"
            >
            </el-pagination>
        </div>
        <Dialog ref="deletePaper" title="删除试卷" :confirmHandler="confirmDeletePaper">
            <div class="delete-dialog">删除后不可恢复</div>
        </Dialog>
    </div>
</template>

<script>
import PaperManageHead from "components/admin/paperManage/PaperManageHead";
import PaperManageTable from "components/admin/paperManage/PaperManageTable";
import Dialog from "components/admin/paperManage/common/Dialog";
import AnswerDetail from "components/AnswerDetail";

import { theoryGetPaperList, theoryLibSelect, theoryDeletePaper } from "@/utils/apis";
export default {
    name: "PaperManage",
    data() {
        return {
            tableData: [],
            questionLibraryList: [],
            getListParams: {
                paging: "1",
                page: 1,
                pageSize: 10,
                category_id: "",
                search: "",
            },
            totalPaperCount: 0,
            currentPage: 1,
            deleteId: "",
        };
    },
    components: {
        PaperManageHead,
        PaperManageTable,
        Dialog,
        AnswerDetail,
    },
    watch: {
        "getListParams.category_id"() {
            this.getPaperList();
        },
        "getListParams.search"() {
            this.getPaperList();
        },
        "getListParams.page"() {
            this.getPaperList();
        },
    },
    computed: {
        packTableData() {
            let { tableData } = this;
            return tableData;
        },
    },
    methods: {
        async getPaperList() {
            let paperTypes = ["随机组卷", "择题组卷"];
            let res = await theoryGetPaperList(this.getListParams);
            if (res.code == 200) {
                res.data.list.forEach((item) => {
                    item.questionCount = `${item.num}题`;
                    item.paperType = `${paperTypes[item.type - 1]}`;
                });
                this.tableData = res.data.list;
                this.totalPaperCount = res.data.total;
            } else {
                this.$message.warning(res.msg);
            }
        },
        async getQuestionLibrary() {
            let res = await theoryLibSelect();
            if (res.code == 200) {
                this.questionLibraryList = res.data.map((item, index) => {
                    let packData = (obj) => {
                        return {
                            label: obj.name,
                            value: obj.id,
                        };
                    };
                    let temp = packData(item);
                    if (item.children.length > 0) {
                        temp.children = item.children.map(packData);
                    }
                    return temp;
                });
            } else {
                this.$message.warning(res.msg);
            }
        },
        deletePaper(question) {
            this.deleteId = question.paper_id;
            this.$refs.deletePaper.open();
        },
        async confirmDeletePaper(question) {
            let res = await theoryDeletePaper({ paper_id: this.deleteId });
            if (res.code == 200) {
                this.$message.success(res.msg);
                this.getPaperList();
                this.$refs.deletePaper.close();
            } else {
                this.$message.error(res.msg);
            }
        },
    },
    mounted() {
        this.getPaperList();
        this.getQuestionLibrary();
    },
};
</script>

<style scoped lang="scss">
.paper-manage {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 36px 30px 30px;
    .footer-paging {
        text-align: center;
    }
}
.delete-dialog {
    margin: 50px 0;
}
</style>
