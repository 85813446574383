<template>
    <div class="paper-head">
        <div class="topic-category">
            <div class="title">题库分类：</div>
            <div class="select-box">
                <el-cascader
                    :value="questionLibraryValue"
                    :options="questionLibraryList"
                    @change="questionLibraryChange"
                    clearable
                ></el-cascader>
            </div>
            <div class="search-box">
                <el-input
                    v-model="searchKeyword"
                    placeholder="请输入关键词"
                    clearable
                    @keydown.enter.native="searchHandle(searchKeyword)"
                    @clear="searchHandle(searchKeyword)"
                >
                    <i slot="suffix" class="el-input__icon el-icon-search" @click="searchHandle(searchKeyword)"></i>
                </el-input>
            </div>
        </div>
        <div class="paper-type">
            <el-button class="btn-blue" @click="artificialPaper">择题组卷</el-button>
            <el-button class="btn-blue" @click="randomPaper">随机组卷</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaperHead",
    data() {
        return {
            searchKeyword: "",
        };
    },
    components: {},
    props: {
        searchVal: {
            type: String,
            default: "",
        },
        questionLibraryList: {
            type: Array,
            default() {
                return [];
            },
        },
        questionLibraryValue: {
            type: [String, Number],
            default: "",
        },
    },
    methods: {
        /**择题组卷 */
        artificialPaper() {
            this.$router.push("/admin/artificialPaper");
        },
        /**随机组卷 */
        randomPaper() {
            this.$router.push("/admin/randomPaper");
        },
        searchHandle(value) {
            this.$emit("update:searchVal", value);
        },
        questionLibraryChange(value) {
            let id = value[value.length - 1];
            this.$emit("update:questionLibraryValue", id);
        },
    },
};
</script>

<style scoped lang="scss">
.paper-head {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .topic-category {
        display: flex;
        align-items: center;
        color: #13131b;
        .select-box {
            width: 170px;
            margin-right: 9px;
        }
        .search-box {
            width: 250px;
            .el-icon-search {
                cursor: pointer;
            }
        }
    }
}
</style>
